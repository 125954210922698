import dynamic from "next/dynamic";

export const DynamicHamburgerMenuDefault = dynamic(() =>
  import("scmp-app/components/hamburger-menu/hamburger-menu-default").then(
    module => module.HamburgerMenuDefault,
  ),
);

export const DynamicHamburgerMenuMagazinesStyle = dynamic(() =>
  import("scmp-app/components/hamburger-menu/hamburger-menu-magazines-style").then(
    module => module.HamburgerMenuMagazinesStyle,
  ),
);
